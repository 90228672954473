import React from "react";
import { Layout } from "../../../components";

const Branding = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Branding",
        href: pathname,
        lang: "en"
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/branding/",
      }}
    >
      <section className="single_offer">
        <div className="single_offer__container">
          <div className="single_offer__header text-center">
            <h1>Branding</h1>
          </div>
          <div className="single_offer__content">
            <div class="single_offer__branding">
              <p class="text-center">
                we create brands - logos - visual identification - packagings and labels
              </p>
              <p>
                We create new brands and introduce them to the market. We also help in refreshing and rebranding the existing ones. We develop names, clauses, and advertising slogans. We design logos and visual identification systems, packaging, and product labels for many different industries.
              </p>
              <h3>Why us?</h3>
              <p>
                More than a dozen years of experience. A well-thought-out design process. A rich portfolio of Clients and projects. Experience in many industries. High competence in both Digital and Print.
              </p>
              <h3>Project realization process*</h3>
              <p>
                Depending on the needs or the nature of the project, all or selected design stages apply. We implement the entire process in the Quality Assurance model (clear communication, defined requirements, roles and methods of our work).{" "}
                <i>“By failing to prepare, you are preparing to fail.” </i>
                Benjamin Franklin
              </p>
              <h3>Briefing</h3>
              <p>
                We perform an initial needs analysis, learn about the Client’s business, industry, offer and his expectations regarding the project and goals that we should aim to achieve. We analyze the communication strategy or develop it right from the scratch.
              </p>
              <h3>Research/benchmarking</h3>
              <p>
                We are looking for inspiration and directions for the project. We prepare a list of inspiring creative concepts and directions for the project we are working on. We design stylescapes.
              </p>
              <h3>Copywriting</h3>
              <p>
                We design brand names, clauses, advertising slogans, and content needed for all other materials. We collect data, analyze it, and create high-quality content.
              </p>
              <h3>Design - preliminary designs</h3>
              <p>
                We prepare preliminary projects. We usually present two or three different proposals to our Clients, from which they can select the one they like the most, and which we will continue to work on. We always present recommendations for the project that should be developed.
              </p>
              <h3>Design</h3>
              <p>
                The design team develops all the materials according to the chosen creative concept. All established elements are created, e.g. logo, visual identification system, label designs, packaging, ATL / BTL materials. We develop a Brand Book or BrandBook, thanks to which brand identification will be organized.
              </p>
              <h3>Presentation</h3>
              <p>
                After completing our work, we present and discuss the prepared projects with the Client. We explain the idea behind them, justify the solutions used, and present recommendations.
              </p>
              <h3>Feedback</h3>
              <p>
                We believe that when working with us, our Clients decided to trust our experience and competencies. We always discuss our projects and try to ensure that our recommendations are accepted. However, we understand the specifics of some projects, and complicated, especially in large-scale projects, decision-making processes, which is why we value honest feedback, which we take into account during design works.
              </p>
              <h3>Transfer of the projects and their rights</h3>
              <p>
                After accepting all projects, we provide files in the agreed formats and the right to use them in the agreed scope.
              </p>
              <h3>Print support</h3>
              <p>
                We have many years of experience and extensive contacts in the printing industry. We advise, select the best solutions, and negotiate terms regarding print for our Clients.
              </p>
              <h3>Brand care - Framework agreement</h3>
              <p>
                After completion of our work, we offer constant mutual cooperation. Our cooperation is then most often based on a framework agreement with a fixed price list, thanks to which, apart from more favorable terms of cooperation, knowledge of the brand, and the omission of the stage of price estimation for individual projects, we shorten the time of their realization. We love bringing brands to life and developing existing ones - thank you for considering entrusting us with this exciting task!

              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Branding;
